
import { computed, defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  setup() {
    const store = useStore();

    return {
      userDetail: computed(() => store.getters.currentUser),
    };
  },
  components: {
    KTUserMenu,
  },
});
