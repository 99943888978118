
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { currentSelectableYear } from "@/core/constants/const";
import { footerWidthFluid } from "@/core/helpers/config";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const store = useStore();

    const router = useRouter();

    const changeYearHandler = (e) => {
      store.commit(Mutations.SET_YEAR, e.target.value.toString());
      router.go(0);
    };

    return {
      footerWidthFluid,
      currentYear: computed(() => store.getters.getSelectedYear),
      currentSelectableYear,
      changeYearHandler,
    };
  },
});
