
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { ButtonsType } from '../../core/enums/buttons.enums';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import MainMenuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: 'kt-menu',

  components: {
    FontAwesomeIcon,
  },

  setup() {
    const { t, te } = useI18n();
    const router = useRouter();
    const scrollElRef = ref<null | HTMLElement>(null);
		const { push } = useRouterCustom();

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const onMenuItemClicked = (item) => {
      if (item.sub?.length === 0) {
        router.push({
          path: item.route,
        });
      }
    };

    return {
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      onMenuItemClicked,
			push,
    };
  },

	methods: {
		onClickMenuHeading({ route }) {
			if (route) {
				this.push(route);
			}
		},

    iconFinder(customIcon) {
      switch (customIcon) {
        case ButtonsType.FA_FILE_CIRCLE_XMARK:
          return 'fa-solid fa-file-circle-xmark'
        default:
          return 'fa-solid fa-xmark';
      }
    }
	}
});
